import { Component, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IoGlobals } from './ioglobals';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnDestroy {
    public connect;
    constructor(
        public translate: TranslateService,
        public ws: IoGlobals,
        public title: Title,
        public meta: Meta,
        private router: Router
    ) {
        translate.addLangs(['vi']);
        translate.setDefaultLang('vi');
        const browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/vi/) ? browserLang : 'vi');
        this.ws.ini();
        this.connect = this.ws.result.subscribe((response: any) => {
            if (response.token == this.ws.config.token.company) {
                let data = response.data;
                if (data.shortcut) {
                    data.shortcut = this.ws.BASE_API_URL + 'public/website/' + data.shortcut;
                }
                if (data.logo) {
                    data.logo = this.ws.BASE_API_URL + 'public/website/' + data.logo;
                }
                this.title.setTitle(data.title);
                this.meta.addTag({ name: 'description', content: data.description });
                this.meta.addTag({ name: 'keywords', content: data.keywords });
                let shortcut = document.querySelector("[type='image/x-icon']");
                if (shortcut && data.shortcut) {
                    shortcut.setAttribute('href', data.shortcut);
                }
                this.translate.set('company', data);

            }
            if (response.token == 'browsersIndexChecklogin') {
                if (response.status == 0 || response.data.skip == false) {
                    this.ws.USERS.info.remove();
                    this.ws.USERS.token.remove();
                    this.router.navigate(['/login']);
                }
            }
        });
    }
    ngOnDestroy() {
        if (this.connect) {
            this.connect.unsubscribe();
        }
    }
}
