import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { IoGlobals } from "../../ioglobals";
import { Observable } from 'rxjs';
@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    public type = {
        admin: "5ab9bd4421e28df1fd48c5f580fd3f2d32643700",
        customer: "a374d26a042cad4ffaace2ab4d60f49faaea9835"
    }
    constructor(
        public ws: IoGlobals,
        private router: Router
    ) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const info = this.ws.USERS.info.get();
        if (Object.keys(info).length > 0) {
            if (info.type == "a374d26a042cad4ffaace2ab4d60f49faaea9835") {
                this.router.navigate(['/search-product']);
            }
            this.ws.USERS.token.send();
            return true;
        } else {
            this.router.navigate(['/login']);
            return false;
        }
    }
}