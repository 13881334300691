import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class StoreService {
    constructor() { }
   
    public store = {
        id : 'id',
        token : null,
        ini : (option) => {
            this.store.id = (option.id) ? option.id : "id";
            this.store.token = (option.token) ? option.token : null;
        },
        set : (list :any) => {
            let skip = (Array.isArray(list));
            if(skip == true){
                let data = list.reduce((n , o ,i) => {
                    if(o[this.store.id]){
                        n[o[this.store.id]] = o;
                    }
                    return n;
                },{});
                if(Object.keys(data).length == list.length){
                    this.store._set(data);
                    return true;
                }else{
                    return false;
                }
            }else if(typeof list === 'object'){
                this.store._set(list);
                return true;
            }else{
                return false;
            }
            
        },
        get:()=> {
            return (window[this.store.token]) ? Object.values(window[this.store.token]) : null; 
        },
        _set : (data) => {
            window[this.store.token] = (window[this.store.token]) ? Object.assign(window[this.store.token] , data) : data;
        },
        compaid : () => {

        }
    }
}
