import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from "./services/auth/auth.guard";
import { IoGlobals } from "./ioglobals";
import { StoreService } from "./services/store/store.service";
import { MaterialModule } from "./material";
import {
    MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS
} from '@angular/material';
import { AppDateAdapter, APP_DATE_FORMATS } from "./services/mask/datepicker";

import { AppComponent } from './app.component';
import { NotFoundComponent } from './not-found/not-found.component';

export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient);
}
const appRoutes: Routes = [
    { path: '', loadChildren: './layout/layout.module#LayoutModule', canActivate: [AuthGuard] },
    { path: 'login', redirectTo: 'browsers' },
    { path: 'browsers', loadChildren: './browsers/browsers.module#BrowsersModule' },
    { path: 'search-product', loadChildren: './search-product/search-product.module#SearchProductModule' },
    { path: 'not-found', component: NotFoundComponent },
    { path: '**', redirectTo: 'not-found' },
];

@NgModule({
    declarations: [
        AppComponent,
        NotFoundComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        MaterialModule
    ],
    providers: [
        IoGlobals,
        {
            provide: DateAdapter, useClass: AppDateAdapter
        },
        {
            provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
        },
        { provide: MAT_DATE_LOCALE, useValue: 'vi-GB' },
        AuthGuard,
        StoreService
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {

}
