import { Injectable } from "@angular/core";
import { Subject } from 'rxjs';
import * as io from 'socket.io-client';
import { Router } from '@angular/router';
import { environment } from "src/environments/environment";
export class requetsGlobals {
    token: string;
    params?: Object;
    data?: Object;
    client?: boolean
}
export class responseGlobals {
    token: string;
    status?: number;
    message?: string;
    data?: any;
}
@Injectable()
export class IoGlobals {

    public BASE_API_URL: string = environment.url;
    public socket: any;
    private response = new Subject<responseGlobals>();

    public result = this.response.asObservable();
    constructor(
        public router: Router
    ) {

    }
    public ini() {
        if (!this.socket && this.socket == null) {
            this.socket = io(this.BASE_API_URL, { rejectUnauthorized: false });
            this.socket.on('connect', (data) => {
                console.log('connect at Time: ' + Date());
                console.log('connect', this.socket.io.engine.transport.name);
                this.listener();
            })
        }
    }
    public isRun() {
        this.ini();

    }
    private listener() {
        this.socket.on("client", (response) => {
            this.response.next(response);
        });
        this.socket.on('connect_timeout', (response) => {

        });
        this.socket.on('connect_error', (response) => {
            console.log('connect_error => Time: ' + Date());
        });
        this.socket.on('disconnect', (response) => {
            console.log(response);
        })
    }
    public send(option: requetsGlobals) {
        if (option.token) {
            if (option.client) {
                let response: responseGlobals = { status: 1, token: option.token, data: option.data, message: null };
                this.response.next(response);
            } else {
                option['authentication'] = this.USERS.token.get();
                this.socket.emit('server', option);
            }
        }

    }
    public time = {
        date: (e) => {
            if (e) {
                e = (typeof e === 'object') ? e : new Date(e);
                return e.getFullYear() + '-' + (e.getMonth() + 1).toString() + '-' + e.getDate();
            }
            return;
        },
        format: (e) => {
            if (e) {
                e = (typeof e === 'object') ? e : new Date(e);
                return e.getDate() + '/' + (e.getMonth() + 1).toString() + '/' + e.getFullYear();
            }
            return;
        },
        convert: (e: any) => {
            if (e) {
                if (typeof e === 'object') {
                    e = (typeof e === 'object') ? e : new Date(e);
                    let m = (e.getMonth() + 1 > 9) ? e.getMonth() + 1 : '0' + (e.getMonth() + 1).toString();
                    let d = e.getDate() > 9 ? e.getDate() : '0' + e.getDate();
                    return parseInt(e.getFullYear() + m.toString() + d);
                } else {
                    try { return +(parseInt(e.split("-").join(""))) } catch (error) { return 0; }
                }
            }
            return 0;
        }
    }

    public USERS = {
        token: {
            key: "tokenUsers",
            set: (token) => {
                window.localStorage.setItem(this.USERS.token.key, token);
            },
            remove: () => {
                // window.localStorage.removeItem(this.USERS.token.key);
                // this.router.navigate(['/login']);
            },
            get: () => {
                return window.localStorage.getItem(this.USERS.token.key);
            },
            send: () => {
                this.send({ token: 'browsersIndexChecklogin', data: this.USERS.info.get() });
            },
        },
        info: {
            key: "localStorage",
            set: (data) => {
                data = typeof data === 'object' ? JSON.stringify(data) : data;
                window.localStorage.setItem(this.USERS.info.key, data);
            },
            remove: () => {
                window.localStorage.removeItem(this.USERS.info.key);
            },
            get: () => {
                try {
                    if (window.localStorage.getItem(this.USERS.info.key)) {
                        let data = window.localStorage.getItem(this.USERS.info.key);
                        return JSON.parse(data);
                    } else {
                        return {};
                    }
                } catch (error) {
                    return {};
                }
            },
            checktypecustomer: () => {
                let info = this.USERS.info.get();
                return (info.type == "a374d26a042cad4ffaace2ab4d60f49faaea9835") ? true : false;
            }
        }
    }

    public CUSTOMER = {
        token: {
            key: "tokenCustomers",
            set: (token) => {
                window.localStorage.setItem(this.CUSTOMER.token.key, token);
            },
            remove: () => {
                window.localStorage.removeItem(this.CUSTOMER.token.key);
                this.router.navigate(['/search-product']);
            },
            get: () => {
                return window.localStorage.getItem(this.CUSTOMER.token.key);
            }
        },
        info: {
            key: "localStorage",
            set: (data) => {
                data = typeof data === 'object' ? JSON.stringify(data) : data;
                window.localStorage.setItem(this.CUSTOMER.info.key, data);
            },
            remove: () => {
                window.localStorage.removeItem(this.CUSTOMER.info.key);
            },
            get: () => {
                try {
                    if (window.localStorage.getItem(this.CUSTOMER.info.key)) {
                        let data = window.localStorage.getItem(this.CUSTOMER.info.key);
                        return JSON.parse(data);
                    } else {
                        return {};
                    }

                } catch (error) {
                    return {};
                }

            }
        }
    }

    public config = {
        token: {
            help: "configHelpGetrow",
            online: "generalIndexOnline",
            keyboard: "generalIndexKeyboard",
            company: "configWebsiteGet",
            onlineAll: "browsersIndexGetall"
        },
        help: (id) => {
            this.send({ token: this.config.token.help, params: { id: id } });
        },
        online: () => {
            this.send({ token: this.config.token.online });
        },
        keyboard: () => {
            this.send({ token: this.config.token.keyboard });
        },
        company: () => {
            this.send({ token: this.config.token.company });
        }
    }
}